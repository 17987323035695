import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import { AvatarCustom } from '../users/Avatar';
import { PatientInvite, inviteStatusToText } from '../center/invite';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as TherapistIcon } from '../../media/teammember.svg';
import SvgIcon from '@mui/icons-material/Close';
import { useTranslation } from '../../contexts/TranslationContext';

interface ReceivedInviteProps {
    invitation: PatientInvite;
    icon: ReactElement;
    onAccept: any;
    onCancel: any;
}

export default function ReceivedPatientInvite(props: ReceivedInviteProps) {
	const { translate } = useTranslation();
    const button =
        <>
            {props.invitation.status === "pending" &&
                <IconButton
                    aria-label="accept"
                    color="secondary"
                    onClick={props.onAccept}>
                    <CheckIcon />
                </IconButton>
            }
            <IconButton
                aria-label="cancel"
                color="secondary"
                onClick={props.onCancel}>
                <CloseIcon />
            </IconButton>
        </>

    return (
        <ListItem
            divider
            secondaryAction={button}>
            <ListItemAvatar>
                <AvatarCustom id={props.invitation.therapistID}>
                    <SvgIcon component={TherapistIcon}
                        color="inherit"
                        viewBox="0 0 64 64"
                        style={{ margin: "auto", color: "inherit" }} />
                </AvatarCustom>
            </ListItemAvatar>
            <ListItemText primary={props.invitation.therapistEmail}
                secondary={translate(`[patient_invitation_status] ${inviteStatusToText(props.invitation.status)}`)}
                sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }} />
        </ListItem>
    )
}
