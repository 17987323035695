import { Button, Link, Typography } from "@mui/material";
import { useTranslation } from "../../contexts/TranslationContext";

function VRAccess() {
	const { translate } = useTranslation();
	return (
		<>
			<Typography variant="h5">
				{translate("[vraccess_requirements_title]")}
			</Typography>
			<Typography variant="body1" gutterBottom component="div">
				<p>{translate("[vraccess_requirements_description1]")}<Link href="https://www.recognition.es/" target="_blank">{translate("[vraccess_recognition]")}</Link>.</p>
				<p>{translate("[vraccess_requirements_description1]")}<Link href="https://www.meta.com/" target="_blank">{translate("[vraccess_meta]")}</Link>.</p>
				<p>{translate("[vraccess_requirements_description2]")}
					<Link href="https://www.meta.com/es/quest/quest-3/" target="_blank"> {translate("[vraccess_meta_quest3]")}</Link>, <Link href="https://www.meta.com/es/quest/quest-3s/" target="_blank">{translate("[vraccess_meta_quest3S]")}</Link>.</p>
			</Typography>

			<Typography variant="h5">
				{translate("[vraccess_steps_title]")}
			</Typography>

			<Typography variant="body1" gutterBottom component="div">
				<p>{translate("[vraccess_steps_install_description]")}</p>
				<Button variant="contained" color="primary" href="https://www.meta.com/experiences/4144031432312694" target="_blank">
					{translate("[vraccess_steps_install_button]")}
				</Button>
				<p>{translate("[vraccess_steps_login_description]")}</p>
			</Typography>
		</>
	);
}

export default VRAccess;