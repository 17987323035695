import { ReactComponent as CustomizableIcon } from '../../media/landing/customizable.svg';
import { ReactComponent as Motivate1Icon } from '../../media/landing/motivate1.svg';
import { ReactComponent as Motivate2Icon } from '../../media/landing/motivate2.svg';
import { ReactComponent as RemoteIcon } from '../../media/landing/remote.svg';


export interface AdvantageData {
    title: string,
    description: string,
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const advantagesTitle = "[advantages_title]";

export const advantagesData: AdvantageData[] = [
    {
        "icon": Motivate2Icon,
        "title": "[advantages_motivation_title]",
        "description": "[advantages_motivation_description]",
    },
    {
        "icon": CustomizableIcon,
        "title": "[advantages_efficiency_title]",
        "description": "[advantages_efficiency_description]",
    },
    {
        "icon": RemoteIcon,
        "title": "[advantages_telerehabilitation_title]",
        "description": "[advantages_telerehabilitation_description]",
    },
    {
        "icon": Motivate1Icon,
        "title": "[advantages_effectiveness_title]",
        "description": "[advantages_effectiveness_description]",
    },
];
