import { Facebook, Instagram } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Twitter from '@mui/icons-material/Twitter';

import { Box, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import LogoText from '../../media/logoText';
import { introductionData } from "./introductionData";
import { useTranslation } from '../../contexts/TranslationContext';
import ReactMarkdown from 'react-markdown';

function Introduction() {
	const { translate } = useTranslation();
	const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

	return (
		<>
			<Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start", height: "100%" }} >
				<IconButton color="secondary" style={{ borderRadius: "50%", backgroundColor: "white", marginRight: "10px" }}
					onClick={() => window.open(introductionData.twitter, '_blank')}>
					<Twitter />
				</IconButton>

				<IconButton color="secondary" style={{ borderRadius: "50%", backgroundColor: "white", marginRight: "10px" }}
					onClick={() => window.open(introductionData.instagram, '_blank')}>
					<Instagram />
				</IconButton>

				<IconButton color="secondary" style={{ borderRadius: "50%", backgroundColor: "white", marginRight: "10px" }}
					onClick={() => window.open(introductionData.facebook, '_blank')}>
					<Facebook />
				</IconButton>
			</Box>
			<Box my={4} display="flex" flexDirection={isSmallScreen ? "column" : "row"}>
				<Box width={isSmallScreen ? "100%" : "70%"}>


					<LogoText
						width={isSmallScreen ? "80%" : 400}
						fill="white" />

					<Typography variant="subtitle1" gutterBottom component="div">
						<ReactMarkdown>
							{translate(introductionData.subtitle)}
						</ReactMarkdown>
					</Typography>

					<Typography variant="body1" gutterBottom component="div">
						<ReactMarkdown> 
							{translate(introductionData.paragraph)}
						</ReactMarkdown>
					</Typography>
					<br />
					<Grid container spacing={2}>
						{introductionData.aplications.map((entry, index) => {
							return (
								<Grid item xs={12} md={6} key={index} >
									<Box display="flex" flexDirection="row" gap={2} alignItems="center">
										<CheckCircleIcon fontSize="small" color="primary" />
										<Typography variant="subtitle1">{translate(entry.title)}</Typography>
									</Box>
								</Grid>)
						})
						}
					</Grid>

				</Box>

				<Box width={isSmallScreen ? "100%" : "30%"}
					maxWidth={600}
					alignItems="center" justifyContent="space-around">
					<ReactCompareSlider
						position={30}
						changePositionOnHover={false}
						itemOne={<ReactCompareSliderImage src={introductionData.image2} alt="Usuario en la Realidad" />}
						itemTwo={<ReactCompareSliderImage src={introductionData.image1} alt="Usuario en VR" />}
					/>
				</Box>
			</Box>
		</>
	);
}

export default Introduction;