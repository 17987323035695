import { Box, Typography, useMediaQuery } from "@mui/material";
import { ourTechnologyData } from "./ourTechnologyData";
import { useTranslation } from "../../contexts/TranslationContext";
import ReactMarkdown from "react-markdown";

function OurTechnology() {
	const { translate } = useTranslation();
	const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

	return (
		<Box my={10}>
			<Typography variant="h2" gutterBottom>
				{translate(ourTechnologyData.title)}
			</Typography>
			<Typography variant="body1" component="div" gutterBottom>
				<ReactMarkdown>
					{translate(ourTechnologyData.paragraph1)}
				</ReactMarkdown>
			</Typography>
			<Box
				display="flex"
				flexDirection={isSmallScreen ? "column" : "row"}
				alignItems="center"
				justifyContent="center"
				height="auto"
				width="100%"
			>
				<Box
					display="grid"
					gridTemplateColumns={isSmallScreen ? "1fr 1fr" : "repeat(2, 1fr)"}
					gap={2}
					width={isSmallScreen ? "100%" : "50%"}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						marginBottom: isSmallScreen ? 2 : 0,
					}}
				>
					{ourTechnologyData.screenshots.map((screenshot, index) => (
						<img
							src={screenshot}
							key={index}
							style={{
								width: "90%",
								aspectRatio: "1 / 1",
								objectFit: "cover",
								borderRadius: 8,
								boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
								margin: "auto", // Ensures the images stay centered
							}}
						/>
					))}
				</Box>

				<Box
					width={isSmallScreen ? "100%" : "50%"}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<video
						height={isSmallScreen ? "auto" : "600px"}
						width={isSmallScreen ? "100%" : "auto"}
						style={{
							aspectRatio: "9 / 16",
							border: "none",
							borderRadius: 8,
							boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
						}}
						src={ourTechnologyData.video}
						title="video re-cognition"
						autoPlay
						muted
						loop
						playsInline
						preload="auto"
					/>
				</Box>

			</Box>


		</Box>
	)
}

export default OurTechnology;
