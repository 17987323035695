import { Box, Grid, SvgIcon, Typography, useTheme } from '@mui/material';
import { advantagesData, advantagesTitle } from "./advantagesData"
import { useTranslation } from '../../contexts/TranslationContext';
import ReactMarkdown from 'react-markdown';



function Advantages() {
	const { translate } = useTranslation();

	const theme = useTheme();

	return (
		<>
			<Typography variant="h2" gutterBottom>
				{translate(advantagesTitle)}
			</Typography>
			<Grid container spacing={2}>
				{advantagesData.map((entry, index) => {
					return (
						<Grid item xs={12} sm={6} lg={3} key={index}>
							<Box display="flex" alignContent="center" flexDirection="column">
								<SvgIcon component={entry.icon}
									color="inherit"
									viewBox="0 0 200 200"
									style={{
										'--primary-color': theme.palette.primary.main,
										'--stroke-color': theme.palette.text.primary,
										color: "inherit",
										width: "150px",
										height: "150px",
									} as React.CSSProperties} />
								<Typography variant="h5" gutterBottom>
									{translate(entry.title)}
								</Typography>
								<Typography variant="body1" gutterBottom component="div">
									<ReactMarkdown>
										{translate(entry.description)}
									</ReactMarkdown>
								</Typography>
							</Box>
						</Grid>)
				})}
			</Grid>
		</>
	);
}

export default Advantages;