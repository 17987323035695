export default function TherapistLogo(props: any) {

	const { width, height, primary, secondary, stroke } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M66.416 42.225c-1.862 1.744 -9.2 8.686 -11.337 10.059 -0.3 -0.365 -4.8 -6.428 -4.8 -6.428a0.88 0.88 0 0 0 -1.45 0.034L38.764 61.076l-6.372 -2.512a0.76 0.76 0 0 0 -0.913 0.251L17.641 77.46a0.607 0.607 0 0 0 0.525 0.97l47.188 -3.23a0.874 0.874 0 0 0 0.811 -0.822l1.77 -31.444a0.9 0.9 0 0 0 -1.519 -0.709Z" fill={primary} strokeWidth="1"></path>
			<path d="M72.157 119.539 9.43 128.856a2.307 2.307 0 0 1 -2.666 -2.329V36.261a2.5 2.5 0 0 1 0.714 -1.621 2.255 2.255 0 0 1 1.644 -0.662c9.978 0.171 88.176 1.495 93.211 1.575a2.548 2.548 0 0 1 2.438 2.375v51.527a0.71 0.71 0 0 0 1.4 0V37.928a3.835 3.835 0 0 0 -3.819 -3.985c-5.024 -0.1 -83.221 -1.575 -93.2 -1.758a4.029 4.029 0 0 0 -4.133 4.076c0 96.62 -0.192 90.65 0.4 91.944a4.043 4.043 0 0 0 4.262 2.295l62.659 -9.773a0.612 0.612 0 0 0 0.5 -0.685 0.6 0.6 0 0 0 -0.683 -0.503Zm62.636 -9.533 -7.878 1.1a2.839 2.839 0 0 1 -2.957 -1.644c-0.38 -0.867 -0.2 1.527 -0.115 -28.9a2.653 2.653 0 0 1 2.467 -2.648c38.4 -2.61 36.7 -2.787 37.7 -2.432a2.833 2.833 0 0 1 1.918 2.637l0.08 6.679a0.6 0.6 0 0 0 1.21 0c0.049 -7.187 0.216 -7.265 -0.24 -8.369a4.288 4.288 0 0 0 -4.2 -2.6c-3.448 0.206 -31.878 1.987 -36.582 2.3a4.455 4.455 0 0 0 -4.156 4.441c0.012 3.848 0.1 24.879 0.126 27.733a4.284 4.284 0 0 0 4.967 4.2l7.843 -1.3a0.6 0.6 0 0 0 0.5 -0.685 0.61 0.61 0 0 0 -0.683 -0.512Zm28.395 -74.784c-40.1 -0.986 -37.238 -1.058 -38.363 -0.594a4.411 4.411 0 0 0 -2.768 4.042v27.847a4.361 4.361 0 0 0 4.675 4.384l36.684 -2.489a4.159 4.159 0 0 0 3.939 -4.338V39.321a4.293 4.293 0 0 0 -4.167 -4.088Zm-0.012 1.21a2.913 2.913 0 0 1 2 0.879 2.818 2.818 0 0 1 0.708 2.01v24.753A2.839 2.839 0 0 1 163.3 66.7l-36.7 2.409a2.637 2.637 0 0 1 -2.78 -2.592c0 -30.409 -0.081 -28.177 0.189 -28.863a2.655 2.655 0 0 1 1.45 -1.427c0.737 -0.29 -2.159 -0.23 37.711 0.217Z" fill={stroke} strokeWidth="1"></path>
			<path d="M19.021 76.857c2.352 -0.183 46.229 -3.129 46.229 -3.129a0.594 0.594 0 0 1 0.65 0.548 0.615 0.615 0 0 1 -0.559 0.663l-47.154 3.779a0.865 0.865 0 0 1 -0.673 -0.24c-0.183 -0.171 -0.138 -0.411 -0.138 -0.662V42.879c0 -0.32 0.423 -0.594 0.743 -0.594a0.607 0.607 0 0 1 0.605 0.594s0.297 31.466 0.297 33.978Z" fill={stroke} strokeWidth="1"></path>
			<path d="M135.167 93.285c1 -0.2 1.9 -2.79 2.4 -3.79a0.618 0.618 0 0 1 0.81 -0.309 0.606 0.606 0 0 1 0.309 0.8 14.846 14.846 0 0 1 -1.542 3.813c-0.811 1.118 -2.226 1.724 -3.208 0.742 -0.788 -0.766 -1.043 -2.484 -2.135 -3.414 -0.449 -0.419 -0.8 -0.3 -1.279 0.08a6.909 6.909 0 0 0 -0.982 0.982 15.763 15.763 0 0 0 -2.18 4.476 0.587 0.587 0 0 1 -0.742 0.422 0.6 0.6 0 0 1 -0.423 -0.719 13.159 13.159 0 0 1 1.975 -5.241c2.267 -3.023 4.668 -2.837 6.508 1.165a3.255 3.255 0 0 0 0.489 0.993Z" fill={primary} strokeWidth="1"></path>
			<path d="M139.4 40.7a11.354 11.354 0 0 0 0 22.7 11.012 11.012 0 0 0 10.79 -11.348c-0.125 -5.91 -4.541 -11.352 -10.79 -11.352Zm0 20.952a9.416 9.416 0 0 1 -9.2 -9.6c0 -5.638 4.63 -9.942 9.693 -9.6 -0.089 1.543 -0.55 8.074 -0.607 9.455a0.9 0.9 0 0 0 1.347 0.788 83.387 83.387 0 0 0 7.256 -5c2.979 6.493 -1.689 14.012 -8.489 13.952Z" fill={primary} strokeWidth="1"></path>
			<path d="M74.772 86.432a0.619 0.619 0 0 0 -0.651 -0.57S24.569 89.024 21 89.275a3.363 3.363 0 0 0 -3.116 3.289c-0.034 2.42 -0.3 26.979 -0.3 26.979a0.6 0.6 0 0 0 1.2 0.023s0.418 -12.833 0.675 -20.857l6.12 -0.392c-0.341 6.179 -0.741 14.391 -0.7 20.557a0.606 0.606 0 1 0 1.21 0.057c0.641 -6.388 1 -14.262 1.235 -20.727 10.319 -0.664 36.058 -2.537 38.277 -2.749 -0.407 6.255 -0.793 12.5 -0.793 18a0.612 0.612 0 0 0 1.222 0.069c0.505 -5.44 0.979 -11.249 1.337 -18.239 1.371 -0.135 2.154 -0.217 2.154 -0.217a0.609 0.609 0 0 0 0.559 -0.65 0.6 0.6 0 0 0 -0.65 -0.56s-0.725 0.029 -2 0.088c0.075 -1.7 0.112 -4.662 0.117 -6.372l6.651 -0.5a0.611 0.611 0 0 0 0.574 -0.642ZM19.5 97.493c0.079 -2.47 0.137 -4.314 0.153 -4.895a1.589 1.589 0 0 1 1.485 -1.553c0.584 -0.039 2.4 -0.173 5.039 -0.368 -0.133 1.539 -0.423 4.928 -0.511 6.209 -0.166 0.014 -5.266 0.514 -6.166 0.607Zm46.2 -3.468c0.105 0 -25.671 1.726 -38.327 2.725 0.034 -1.166 0.069 -5.548 0.068 -6.167 9.81 -0.729 28.353 -2.124 38.9 -2.919 -0.073 0.709 -0.541 5.257 -0.641 6.361Z" fill={stroke} strokeWidth="1"></path>
			<path d="M74.34 48.48a15.181 15.181 0 0 0 3.037 0.034A23.907 23.907 0 0 0 81.853 48a0.605 0.605 0 0 0 -0.023 -1.21c-0.64 -0.08 -1.267 -0.171 -1.918 -0.194a32.208 32.208 0 0 0 -3.814 0.251 16.575 16.575 0 0 0 -1.872 0.446 0.585 0.585 0 0 0 -0.537 0.65 0.609 0.609 0 0 0 0.651 0.537Z" fill={stroke} strokeWidth="1"></path>
			<path d="M91.153 47.728a23.692 23.692 0 0 0 4.316 -0.035 14.811 14.811 0 0 0 2.9 -0.411 0.612 0.612 0 0 0 -0.069 -1.221 17.5 17.5 0 0 0 -2.8 -0.16 13.2 13.2 0 0 0 -4.5 0.639 0.6 0.6 0 1 0 0.148 1.188Z" fill={stroke} strokeWidth="1"></path>
			<path d="M73.085 55.594a24.493 24.493 0 0 0 2.455 0.308 44.231 44.231 0 0 0 4.932 -0.08c0.822 -0.079 1.633 -0.228 2.444 -0.365a0.606 0.606 0 1 0 -0.069 -1.21 54.363 54.363 0 0 0 -7.3 -0.069c-0.663 0.019 -2.151 0.194 -2.432 0.217a0.6 0.6 0 0 0 -0.034 1.2Z" fill={primary} strokeWidth="1"></path>
			<path d="M89.024 54.925a28.786 28.786 0 0 0 2.969 0.308 54.588 54.588 0 0 0 5.914 -0.16c0.993 -0.091 1.964 -0.251 2.934 -0.388a0.606 0.606 0 1 0 -0.057 -1.21c-0.708 -0.034 -1.416 -0.08 -2.124 -0.091 -1.863 -0.046 -7.4 0.164 -7.638 0.182 -0.651 0.046 -1.3 0.115 -1.964 0.16a0.6 0.6 0 0 0 -0.034 1.2Z" fill={primary} strokeWidth="1"></path>
			<path d="M75.572 64.842c0.348 0.033 0.974 0.126 1.439 0.126s2.271 -0.1 2.843 -0.183c0.49 -0.068 0.947 -0.194 1.427 -0.3a0.611 0.611 0 0 0 -0.023 -1.221 12.1 12.1 0 0 0 -4.384 -0.023 8.41 8.41 0 0 0 -1.428 0.411 0.6 0.6 0 1 0 0.126 1.187Z" fill={stroke} strokeWidth="1"></path>
			<path d="M70.739 72.653c0.8 0.036 1.515 0.1 2.991 0.068 1.451 -0.033 4.536 -0.3 5.949 -0.513 0.982 -0.149 1.952 -0.377 2.934 -0.583a0.61 0.61 0 0 0 -0.16 -1.21 69.141 69.141 0 0 0 -7.079 0.422 40.736 40.736 0 0 0 -4.7 0.617 0.6 0.6 0 0 0 -0.559 0.639 0.592 0.592 0 0 0 0.624 0.56Z" fill={primary} strokeWidth="1"></path>
			<path d="M92.453 64.057a7.719 7.719 0 0 0 1.176 0.239 15.6 15.6 0 0 0 2.386 0.012 10.817 10.817 0 0 0 1.21 -0.263 0.611 0.611 0 1 0 -0.034 -1.221 9.209 9.209 0 0 0 -2.832 -0.274 5.413 5.413 0 0 0 -1.929 0.308 0.617 0.617 0 0 0 -0.594 0.616 0.607 0.607 0 0 0 0.617 0.583Z" fill={stroke} strokeWidth="1"></path>
			<path d="M88.472 72.045c0.872 0.02 1.734 0.075 3.049 0.046a50.99 50.99 0 0 0 9.1 -0.879 0.614 0.614 0 0 0 -0.115 -1.222 62.868 62.868 0 0 0 -7.273 0.229 32.2 32.2 0 0 0 -4.852 0.639 0.578 0.578 0 0 0 -0.548 0.639 0.6 0.6 0 0 0 0.639 0.548Z" fill={primary} strokeWidth="1"></path>
			<path d="M21.83 101.865a2.861 2.861 0 0 0 0.057 1.964c0.219 0.818 1.031 0.789 1.211 -0.034a3.009 3.009 0 0 0 -0.069 -1.952c-0.11 -0.313 -0.274 -0.6 -0.605 -0.594s-0.51 0.296 -0.594 0.616Z" fill={stroke} strokeWidth="1"></path>
			<path d="M36.219 103.554c4.458 -0.518 9.652 -0.9 17.457 -2.443a0.624 0.624 0 0 0 0.525 -0.7 0.613 0.613 0 0 0 -0.685 -0.514c-2.86 0.231 -2.244 0.095 -6.759 0.583a63.8 63.8 0 0 0 -10.767 1.884 0.615 0.615 0 0 0 -0.468 0.707 0.606 0.606 0 0 0 0.697 0.483Z" fill={stroke} strokeWidth="1"></path>
			<path d="M34.1 112.19c0.1 0 1.118 0.017 1.553 -0.023a18.746 18.746 0 0 0 4.51 -0.947 0.61 0.61 0 0 0 -0.149 -1.211 15.007 15.007 0 0 0 -1.587 -0.034 21.611 21.611 0 0 0 -3.083 0.491 13.516 13.516 0 0 0 -1.484 0.548 0.615 0.615 0 0 0 -0.468 0.708 0.6 0.6 0 0 0 0.708 0.468Z" fill={stroke} strokeWidth="1"></path>
			<path d="M81.81 99.038c5.351 -0.128 10.172 -1.163 15.459 -2a0.594 0.594 0 0 0 0.549 -0.65 0.611 0.611 0 0 0 -0.651 -0.56c-1.268 0.023 -2.523 0.057 -3.779 0.16a115.5 115.5 0 0 0 -11.7 1.861 0.6 0.6 0 1 0 0.125 1.187Z" fill={secondary} strokeWidth="1"></path>
			<path d="M76.642 108.087a162.91 162.91 0 0 0 16.372 -2.946 0.606 0.606 0 0 0 0.491 -0.7 0.618 0.618 0 0 0 -0.708 -0.5c-3.419 0.383 -2.967 0.237 -6.348 0.777a58.628 58.628 0 0 0 -10.07 2.192 0.6 0.6 0 0 0 0.263 1.177Z" fill={secondary} strokeWidth="1"></path>
			<path d="M87.407 112.1a45.164 45.164 0 0 0 5.926 -0.765 29.068 29.068 0 0 0 3.916 -0.867 0.612 0.612 0 0 0 -0.171 -1.211 29.954 29.954 0 0 0 -5.012 0.468 22.358 22.358 0 0 0 -4.91 1.2 0.6 0.6 0 0 0 0.251 1.176Z" fill={secondary} strokeWidth="1"></path>
			<path d="M155.109 46.512a12.764 12.764 0 0 0 1.45 0.2 21.246 21.246 0 0 0 2.934 -0.091 13.328 13.328 0 0 0 1.473 -0.331 0.615 0.615 0 0 0 0.559 -0.663 0.6 0.6 0 0 0 -0.662 -0.548 13.462 13.462 0 0 0 -3.471 -0.148 8.059 8.059 0 0 0 -2.34 0.377 0.592 0.592 0 0 0 -0.571 0.628 0.6 0.6 0 0 0 0.628 0.576Z" fill={stroke} strokeWidth="1"></path>
			<path d="M155.132 52.555a3.681 3.681 0 0 0 2.238 -0.08c0.8 -0.193 0.805 -1.047 -0.035 -1.21a4.1 4.1 0 0 0 -2.249 0.1c-0.317 0.1 -0.582 0.274 -0.571 0.617s0.293 0.502 0.617 0.573Z" fill={primary} strokeWidth="1"></path>
			<path d="M154.194 59.5a27.685 27.685 0 0 0 4.647 0.022 20.279 20.279 0 0 0 3.117 -0.331 0.62 0.62 0 0 0 0.6 -0.628 0.608 0.608 0 0 0 -0.616 -0.593 18.118 18.118 0 0 0 -3.939 -0.217 13.308 13.308 0 0 0 -3.939 0.559 0.6 0.6 0 1 0 0.125 1.188Z" fill={primary} strokeWidth="1"></path>
			<path d="M143.982 114.877a2.43 2.43 0 0 0 1.586 0.262 3.545 3.545 0 0 0 2.5 -3.608 0.6 0.6 0 0 0 -0.5 -0.7 0.618 0.618 0 0 0 -0.708 0.491 8.4 8.4 0 0 1 -1.12 1.678 2 2 0 0 1 -0.765 0.537 1.53 1.53 0 0 1 -0.651 0.182 0.617 0.617 0 0 0 -0.754 0.411 0.6 0.6 0 0 0 0.412 0.747Z" fill={stroke} strokeWidth="1"></path>
			<path d="M146.768 101.854c0.4 0.6 0 1.575 -0.9 2.169s-1.941 0.594 -2.34 0 0 -1.564 0.89 -2.169 1.953 -0.596 2.35 0Z" fill={stroke} strokeWidth="1"></path>
			<path d="M117.546 112.507a11.772 11.772 0 0 1 1.7 2.706 13.276 13.276 0 0 1 1.29 6.189 15.33 15.33 0 0 1 -0.32 3.242 0.61 0.61 0 0 0 0.411 0.765 0.6 0.6 0 0 0 0.754 -0.411 13.172 13.172 0 0 0 0.868 -3.505 12.627 12.627 0 0 0 -1.473 -7.079 9.611 9.611 0 0 0 -2.4 -2.774 0.6 0.6 0 0 0 -0.83 0.867Z" fill={stroke} strokeWidth="1"></path>
			<path d="M122.239 113.919c0.2 0.3 0.612 1.222 0.674 1.347a12.459 12.459 0 0 1 0.959 2.843c0.126 0.525 0.286 1.027 0.365 1.53a0.611 0.611 0 0 0 1.222 -0.012 9.235 9.235 0 0 0 0.1 -1.781 8.2 8.2 0 0 0 -1.153 -3.448 4.937 4.937 0 0 0 -1.3 -1.313 0.61 0.61 0 0 0 -0.856 -0.023 0.6 0.6 0 0 0 -0.011 0.857Z" fill={stroke} strokeWidth="1"></path>
			<path d="M126.509 114.108c0.038 0.141 0.183 1.011 0.229 1.21 0.08 0.389 0.171 0.777 0.239 1.176s0.092 0.811 0.16 1.211c0.057 0.445 0.172 0.845 0.217 1.267a0.612 0.612 0 0 0 1.222 0.08 8.055 8.055 0 0 0 0.274 -1.4 7.716 7.716 0 0 0 -0.468 -2.855 5.4 5.4 0 0 0 -0.811 -1.256 0.6 0.6 0 0 0 -1.062 0.571Z" fill={stroke} strokeWidth="1"></path>
			<path d="M132.2 124.282c1.826 1.112 4.262 2.312 6.5 3.951a0.608 0.608 0 0 1 -0.651 1.027c-2.152 -1.187 -4.418 -1.966 -7.33 -3.79a0.893 0.893 0 0 1 -0.4 -0.971 22.573 22.573 0 0 0 -0.422 -10.093 0.6 0.6 0 1 1 1.142 -0.388 17.4 17.4 0 0 1 1.161 10.264Z" fill={stroke} strokeWidth="1"></path>
			<path d="M123.906 130.376c-5.385 1.747 -10.876 -1.944 -11.28 -7a5.526 5.526 0 0 1 0.08 -1.736 0.614 0.614 0 0 1 0.7 -0.5 0.622 0.622 0 0 1 0.491 0.7 4.348 4.348 0 0 0 0.08 1.336 7.421 7.421 0 0 0 9.751 5.4 0.88 0.88 0 0 1 0.822 0.1c2.178 1.622 3.042 2.127 6.131 4.647a0.605 0.605 0 0 1 0.125 0.857 0.611 0.611 0 0 1 -0.844 0.125c-2.019 -1.365 -4.083 -2.539 -6.056 -3.929Z" fill={stroke} strokeWidth="1"></path>
			<path d="M117.333 119.5c-1.2 -2.9 -0.587 -2.473 -1.073 -2.728 -0.606 -0.319 -8.374 -4.464 -11.122 -6.164A18.243 18.243 0 0 0 104.916 94h0.011A18.322 18.322 0 0 0 80.3 85.563a18.6 18.6 0 1 0 22.858 28.194c1.139 0.653 2.387 1.161 3.477 1.781a97.478 97.478 0 0 0 9.762 4.852 0.7 0.7 0 0 0 0.936 -0.89Zm-21.574 -3.235A15.768 15.768 0 0 1 81.579 88.1a16.063 16.063 0 0 1 21.544 6.8h0.012a16.1 16.1 0 0 1 -7.376 21.364Z" fill={stroke} strokeWidth="1"></path>
			<path d="M168.059 97.6a6.171 6.171 0 0 0 -3.037 -3.916 7.368 7.368 0 0 0 -2.569 -9.351c-4.039 -2.77 -10.6 -2.33 -14.18 -5.526 -1.02 -0.91 -1.832 -1.975 -3.334 -1.975a2.045 2.045 0 0 0 -2.078 2.2 3.48 3.48 0 0 0 -2.569 -0.058c-1.208 0.677 -0.675 2.138 -0.422 2.718a1.737 1.737 0 0 0 -1.735 1.5c-0.265 1.323 0.617 3.337 1.187 4.568 1.738 3.732 4.21 7.879 8.072 9.3 0 0.363 0.019 0.729 0.046 1.1 -1.651 -1.51 -5.314 0.151 -4.71 1.273 0.348 0.621 1.221 0.149 2.557 -0.046 1.064 -0.155 1.862 0.04 2.253 -0.225a18.281 18.281 0 0 0 1.589 5.527 0.907 0.907 0 0 0 1.028 0.48c1.908 -0.466 4.3 1.228 3.3 3.231a3.78 3.78 0 0 1 -2.706 1.473 0.878 0.878 0 0 0 -0.731 0.9 11.133 11.133 0 0 0 4.087 8.472 0.857 0.857 0 0 0 1.039 0.022c2.5 -1.817 4.386 -4.32 6.3 -6.645 2.771 -3.354 8.391 -8.68 6.613 -15.022Z" fill={stroke} strokeWidth="1"></path>
			<path d="M194.991 160.69a46.026 46.026 0 0 0 -3.14 -9.6c-3.736 -8.919 -9.338 -16.91 -18.233 -20.9 -3.844 -1.709 -7.471 -1.776 -11.441 -2.478 0.246 -3.668 0.5 -3.777 0.08 -4.293a0.948 0.948 0 0 0 -0.662 -0.3 64.5 64.5 0 0 0 -7.327 0.288 19.8 19.8 0 0 0 -0.291 -3.972 0.605 0.605 0 1 0 -1.21 0.046 13.936 13.936 0 0 0 0.154 4.053c-1.577 0.161 -3.359 0.384 -5.631 0.738 0 -0.315 0.01 -2.984 -0.039 -3.689a0.738 0.738 0 0 0 -0.742 -0.673c-3.969 -0.054 -4.629 -5.58 -4.761 -9.545a0.911 0.911 0 0 0 -0.685 -0.845l-2.1 -0.536a124.232 124.232 0 0 1 4.373 -12.172 0.6 0.6 0 1 0 -1.1 -0.5c-1.817 3.873 -3.567 6.858 -5.24 13.1a0.891 0.891 0 0 0 0.662 1.1l2.318 0.6c0.231 4.524 1.347 9.916 5.88 10.253 0.03 0.312 0.14 2.255 0.189 3.111 -1.558 0.256 -3.121 0.536 -4.7 0.829a0.91 0.91 0 0 0 -0.731 0.833c-0.093 1.58 -0.25 2.564 -0.319 4.419 -14.843 5.639 -25.808 20.831 -26.6 36.627a0.618 0.618 0 0 0 0.571 0.639 0.6 0.6 0 0 0 0.639 -0.57c1.211 -18.017 16.608 -34.245 30.3 -35.849 0.856 -0.1 10.846 -1.234 12.136 -1.405 16.288 -2.088 26.711 6.787 33.217 21.657a45.013 45.013 0 0 1 3.231 9.271 0.61 0.61 0 0 0 0.708 0.491 0.627 0.627 0 0 0 0.494 -0.728Zm-45.867 -31.579a45.6 45.6 0 0 0 -7.025 1.329c0.047 -1.1 0.2 -2.69 0.269 -3.5a103.122 103.122 0 0 1 18.245 -2.032c-0.085 1.058 -0.143 2.089 -0.306 3.3 -1.85 0.092 -5.885 0.192 -11.183 0.903Z" fill={stroke} strokeWidth="1"></path>
			<path d="M138.96 160c-0.022 0.338 -0.046 2.357 -0.046 2.694 0 0.986 -0.088 3.642 0.834 3.642 0.813 0 0.85 -1.742 0.959 -3.493a24.364 24.364 0 0 0 -0.057 -3.186c-0.164 -1.232 -0.283 -2.134 -0.959 -2.089 -0.576 0.044 -0.591 0.556 -0.731 2.432Z" fill={stroke} strokeWidth="1"></path>
			<path d="M179.841 157.267c0.1 0.464 0.435 1.963 0.582 2.42 0.346 1.251 0.565 2.194 1.233 2.078 0.709 -0.122 0.575 -1.146 0.423 -2.489a30.89 30.89 0 0 0 -0.754 -3.425c-0.517 -1.553 -0.788 -2.226 -1.381 -2.078 -0.852 0.183 -0.383 2.127 -0.103 3.494Z" fill={stroke} strokeWidth="1"></path>
		</svg>

	);
}
