import top50 from "../../media/landing/top50_2024.png";
import ugr from "../../media/landing/ugrbyn.png";
import cetic from "../../media/landing/cetic.png";
import agredace from "../../media/landing/agredace.png";
import pts from "../../media/landing/pts.png";
import uma from "../../media/landing/uma.png";
import createc from "../../media/landing/createc.png";


export interface CollaboratorData {
	image: string,
}

export const collaboratorsTitle = "Colaboran";

export const collaboratorsData: CollaboratorData[] = [
	{
		"image": pts,
	},
	{
		"image": top50,
	},
	{
		"image": cetic,
	},
	{
		"image": createc,
	},
	{
		"image": uma,
	},
	{
		"image": ugr,
	},
];