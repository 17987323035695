import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/User.context";
import WaveSection from "../landing/WaveSection";
import { Role } from "../login/PremiumUser";
import LicenseProduct from "./LicenseProduct";
import { pricingData } from "./pricingData";
import { useNavigate } from 'react-router-dom';
import { adquireLicense, getStripePortal } from "../../adapters/Firebase";

function PricingTable() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const user = useContext(UserContext);
    const [checkingOut, setCheckingOut] = useState(false);

    async function openLicenseManager() {
        const { data } = await getStripePortal()({
            returnUrl: window.location.origin,
            locale: "auto",
        });
        window.location.assign(data.url);
    }

    async function buyLicense(productID: string) {

        const userID = user?.uid;

        if (!userID) {
            navigate("/login");
            return;
        }
        setCheckingOut(true);
        if (user?.role !== Role.None) {
            openLicenseManager();
            setCheckingOut(false);
            return;
        }

        const result = await adquireLicense(productID);
        if (result.result !== 200) {
            alert(`An error occured: ${result.message}`);
        }
        else {
            window.location.assign(result.message);
        }
        setCheckingOut(false);
    }

    return (

        <WaveSection marginTop={0} marginBottom={0} >
            <Box gap={2}
                display="flex" justifyContent={isSmallScreen ? "space-evenly" : "center"}
                flexDirection={isSmallScreen ? "column" : "row"}
            >

                <Box flex={1}>
                    <LicenseProduct
                        loading={checkingOut}
                        title={pricingData.patient.title}
                        description={pricingData.patient.description}
                        role={Role.Patient}
                        price={pricingData.patient.price} directPrice
                        features={pricingData.patient.features}
                        onBuy={() => buyLicense("basic")}
                    />
                </Box>
                <Box flex={1} >
                    <LicenseProduct
                        loading={checkingOut}
                        title={pricingData.therapist.title}
                        description={pricingData.therapist.description}
                        price={pricingData.therapist.price} directPrice
                        role={Role.Therapist}
                        features={pricingData.therapist.features}
                        onBuy={() => buyLicense("complete")}
                    />
                </Box>
                <Box flex={1}>
                    <LicenseProduct
                        loading = {false}
                        title={pricingData.centre.title}
                        description={pricingData.centre.description}
                        price={pricingData.centre.price}
                        role={Role.Centre}
                        features={pricingData.centre.features}
                        onBuy={() => navigate("/contact")}
                    />
                </Box>
            </Box>
        </WaveSection>
    );
}

export default PricingTable;
