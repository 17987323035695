import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "../../contexts/TranslationContext";
import DownloadIcon from '@mui/icons-material/Download';
import metaquestback from "../../media/landing/metaquestback.png";
import ReactMarkdown from "react-markdown";


function DownloadDemo() {
  const { translate } = useTranslation();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  return (
    <Box my={10}>
      <Typography variant="h2" gutterBottom>
        {translate("[landing_downloaddemo_title]")}
      </Typography>

      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} alignItems="center" gap={2} >
        <Box display="flex" flexDirection="column" gap={2} justifyContent="stretch" >
          <Typography variant="subtitle1" component="div">
            <ReactMarkdown>
              {translate("[landing_downloaddemo_body1]")}
            </ReactMarkdown>
          </Typography>
          <Typography variant="body1" component="div">
            <ReactMarkdown>
              {translate("[landing_downloaddemo_body2]")}
            </ReactMarkdown>
          </Typography>
          <Button variant="contained" color="primary"
            href="https://www.meta.com/experiences/4144031432312694" target="_blank"
            startIcon={<DownloadIcon />} >
            {translate("[landing_downloaddemo_button]")}
          </Button>
        </Box>
        <Box width="70%">
          <img
            src={metaquestback}
            alt="Meta Quest"
            style={{width: "100%", height: "auto"}}
          />
        </Box>
      </Box >
    </Box>
  )
}

export default DownloadDemo;
