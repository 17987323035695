export default function CenterLogo(props: any) {

    const { width, height, primary, secondary, stroke } = props;
    return (
      <svg width={width} height={height} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="m168.417 65.979 1.775 -0.887 -0.887 -21.006c0 -0.3 -0.3 -0.591 -0.592 -0.887L67.532 15.685c-0.3 0 -0.3 0 -0.3 0.3 0.35 36.392 0.5 46.152 1.48 46.152 1.362 0 -0.628 -42.477 -0.875 -45.688 8.284 2.071 93.863 27.217 99.985 28.529Z" fill={stroke} strokeWidth="1"></path>
        <path d="M169.6 95.86c0.545 8.723 0.059 36.8 0.887 36.39 1.56 -0.52 0.683 -35.939 0.592 -37.573a6.47 6.47 0 0 1 -1.479 1.183Z" fill={stroke} strokeWidth="1"></path>
        <path d="M46.231 63.021c0 1.32 -2.959 1.491 -2.959 -1.479a7.248 7.248 0 0 0 2.959 1.479Z" fill={stroke} strokeWidth="1"></path>
        <path d="M92.384 62.725c0.3 0.3 0.16 0.674 0.078 0.959a17.546 17.546 0 0 1 -1.1 2.4c-1.463 2.03 -4.3 2.259 -6.667 2.259l-3.55 0.3c-4.4 3.424 -10.208 5.888 -14.2 8.284l-5.034 2.923v-0.557c3.156 -1.973 1.113 -0.679 9.467 -5.622 1.485 -0.878 7.692 -4.733 9.467 -5.917l3.846 -0.3c4.734 0.3 6.589 -2.807 6.885 -3.4a4.851 4.851 0 0 1 0.808 -1.329Z" fill={stroke} strokeWidth="1"></path>
        <path d="M90.608 58.287a1.087 1.087 0 0 1 0.3 0.888 4.9 4.9 0 0 1 -2.662 4.733c-0.592 0.3 -1.48 0.592 -1.775 0.3 0.3 -0.591 0.887 -0.887 1.479 -1.479a7.546 7.546 0 0 0 2.658 -4.442Z" fill={stroke} strokeWidth="1"></path>
        <path d="M81.785 55.413c-1.683 1.38 -2.456 6.019 -2.715 7.312 -4.329 2.165 -13.4 5.066 -14.2 5.325 -0.554 0.18 -1.074 0.939 0.3 0.592 6.084 -1.541 13.267 -4.4 14.5 -5.325l0.3 -0.3v-0.3c0.521 -3.129 0.151 -4.9 2.273 -7.022 0.537 -0.537 1.558 -0.951 1.869 -1.262 -0.186 -0.058 -1.005 -0.104 -2.327 0.98Z" fill={stroke} strokeWidth="1"></path>
        <path d="M91.2 54.737c-8.4 11.086 -9.763 -0.3 0.592 -3.846a6.606 6.606 0 0 1 -0.592 3.846Z" fill={secondary} strokeWidth="1"></path>
        <path d="M110.135 93.494s4.437 4.437 9.171 3.846c0 0 2.643 8.063 -4.438 6.212 -6.527 -1.705 -4.733 -10.058 -4.733 -10.058Z" fill={primary} strokeWidth="1"></path>
        <path d="M129.069 115.682c0.3 0 0.592 0.3 0.592 0 1.479 -0.887 -0.592 -4.142 -0.592 -5.325 0.3 -1.775 8.111 -12.774 9 -14.549s0.173 -1.723 0.173 -1.723c-2.958 2.959 -10.65 14.2 -10.946 15.976 0.694 3.483 3.249 4.146 1.773 5.621Z" fill={stroke} strokeWidth="1"></path>
        <path d="M136.169 106.215c-0.151 0.644 0.008 1.411 0.3 1.479a1.367 1.367 0 0 0 1.48 -0.887c0.3 -0.592 0 -1.479 -0.3 -1.479 -0.592 0 -1.344 0.311 -1.48 0.887Z" fill={stroke} strokeWidth="1"></path>
        <path d="M138.536 103.848c0.068 0.288 0.86 1.449 1.184 1.184a1.7 1.7 0 0 0 0.3 -2.367c-0.387 -0.537 -1.239 -0.979 -1.776 -0.592 -0.682 0.489 0.14 1.127 0.292 1.775Z" fill={stroke} strokeWidth="1"></path>
        <path d="M160.134 134.025c0 -0.3 -0.592 -0.592 -0.888 -0.592a31.4 31.4 0 0 0 -3.5 0.99 7.611 7.611 0 0 1 -0.055 -4.54l-1.775 -0.592a10.082 10.082 0 0 0 0.011 5.744c-4.653 1.6 -10.359 3.82 -10.958 4.02 -0.592 0.295 -2.071 0.591 -2.071 1.479 0 0.591 4.734 -1.184 4.734 -1.184s10.354 -3.254 13.017 -4.142c0 0.3 0.592 6.213 1.184 6.213 0.892 -0.296 0.301 -7.396 0.301 -7.396Z" fill={stroke} strokeWidth="1"></path>
        <path d="M147.708 143.788c-6.4 1.58 -20.832 4.972 -28.106 7.4a41.285 41.285 0 0 0 -14.5 8.284 30.4 30.4 0 0 0 -5.325 6.509c-1.479 2.367 -4.438 7.1 -3.55 7.4 0.591 0.3 3.251 -5.328 5.029 -7.4 7.384 -8.59 13.89 -11.582 18.935 -13.313 9.852 -3.381 31.837 -8.513 31.36 -9.468 0.003 -0.891 -2.659 0.3 -3.843 0.588Z" fill={stroke} strokeWidth="1"></path>
        <path d="M190.606 146.747c-4.989 -6.58 -12.673 -10.355 -18.934 -10.355a35.113 35.113 0 0 0 -9.468 1.479c-0.3 0.3 -1.183 0.3 -1.183 0.888 0.3 0.591 0.888 0 1.479 0a25.994 25.994 0 0 1 9.172 -0.592 35.39 35.39 0 0 1 9.763 2.959c9.439 5.081 13.017 15.68 13.313 15.68 1.183 -0.592 -2.104 -7.371 -4.142 -10.059Z" fill={stroke} strokeWidth="1"></path>
        <path d="M94.455 44.086a28.407 28.407 0 0 1 -8.284 -9.763c2.958 1.477 6.213 4.438 8.284 9.763Z" fill={primary} strokeWidth="1"></path>
        <path d="M98.6 38.169c-0.3 -0.887 -2.366 0 -3.55 -7.1 0.292 0.888 2.659 0.296 3.55 7.1Z" fill={primary} strokeWidth="1"></path>
        <path d="M137.945 57.991a71.341 71.341 0 0 1 18.934 -4.437 40.577 40.577 0 0 1 -18.934 4.437Z" fill={primary} strokeWidth="1"></path>
        <path d="M139.128 71.6c5.029 0.3 11.242 1.479 18.934 6.508a120.508 120.508 0 0 1 -18.934 -6.508Z" fill={primary} strokeWidth="1"></path>
        <path d="M87.65 77.518a16.409 16.409 0 0 1 11.242 -3.551 25.141 25.141 0 0 1 -11.242 3.551Z" fill={primary} strokeWidth="1"></path>
        <path d="M60.432 113.02c-0.3 0.591 -0.888 0.887 -1.184 1.479v10.651c0 0.295 -0.3 0.591 -0.591 0.887 -0.888 1.184 -2.071 1.184 -1.776 1.479s1.776 -0.3 2.367 -0.887c1.134 -1.134 1.172 -1.114 1.48 -2.367a21.11 21.11 0 0 0 0.591 -5.325 18.973 18.973 0 0 0 -0.887 -5.917Z" fill={stroke} strokeWidth="1"></path>
        <path d="M76.112 131.362c-0.592 0 -0.888 1.48 -0.888 1.776a31.748 31.748 0 0 0 -1.479 5.917 64.228 64.228 0 0 0 -0.888 7.987 16.344 16.344 0 0 0 1.48 -3.846c0.292 -1.3 2.4 -6.723 2.4 -10.641 0.112 -0.74 -0.037 -1.193 -0.625 -1.193Z" fill={stroke} strokeWidth="1"></path>
        <path d="M81.141 113.316c-0.591 -0.3 -0.887 0.887 -0.887 1.183 -0.3 1.183 -2.367 7.1 -2.663 6.213s-0.592 -2.663 -1.479 -2.663a2.842 2.842 0 0 0 -1.775 2.663 14.67 14.67 0 0 0 0.591 5.029c0.3 0.9 1.522 4.269 2.367 3.846 0.3 -0.3 0 -0.887 0 -0.887a16.45 16.45 0 0 1 -1.479 -7.1c0 -0.591 0 -2.958 0.592 -1.183 0 0.654 1.273 2.573 2.366 1.479 0.711 -0.712 3.198 -8.165 2.367 -8.58Z" fill={stroke} strokeWidth="1"></path>
        <path d="M82.325 115.091s-0.3 7.4 -1.184 7.988a4.4 4.4 0 0 1 -0.3 -1.776c0.004 -1.134 0.599 -6.212 1.484 -6.212Z" fill={stroke} strokeWidth="1"></path>
        <path d="M82.62 119.528a1.56 1.56 0 0 1 1.48 1.184v1.188c-0.168 0.413 -0.3 0.592 -0.888 0.592a7.422 7.422 0 0 0 -0.592 -2.964Z" fill={stroke} strokeWidth="1"></path>
        <path d="M85.283 121.6c3.255 2.071 2.367 6.8 0.592 9.763a12.93 12.93 0 0 1 -2.071 2.367c0.041 10.766 0.151 25.267 -1.775 34.911 -0.83 4.154 -1.995 9.242 -5.03 12.425a10.375 10.375 0 0 1 -10.059 2.959c-4.134 -0.92 -7.417 -3.532 -10.059 -6.509 -2.087 -2.352 -3.312 -5.294 -4.733 -7.988 -0.617 -1.17 -6.213 -12.721 -5.03 -13.017 0.592 -0.3 3.551 7.692 3.846 8.579 1.776 4.142 3.847 8.284 7.1 11.539a22.745 22.745 0 0 0 7.1 5.029 9.546 9.546 0 0 0 12.13 -3.846c2.028 -3.281 2.315 -7.405 2.959 -11.242a70.329 70.329 0 0 0 1.183 -9.468c0.148 -5.922 0.888 -23.076 1.184 -23.372 2.071 -4.143 4.142 -8.285 2.663 -12.13Z" fill={stroke} strokeWidth="1"></path>
        <path d="M74.337 163.61c-2.007 -7.524 -1.344 -16.753 -2.959 -21.6a13.979 13.979 0 0 0 -2.071 -3.55c-4.859 -4.859 -13.086 -3.96 -19.23 -0.888a35.863 35.863 0 0 1 -5.621 2.071c-1.788 0.553 -5.03 0.888 -6.213 -0.887 0.592 -3.846 -0.592 -5.917 -0.592 -5.917a9.541 9.541 0 0 0 -0.592 3.55s-3.254 -0.592 -3.55 -0.592a19.49 19.49 0 0 0 -10.059 1.479 11.966 11.966 0 0 0 -5.621 4.438C14.6 146.175 15.048 152.1 16.35 157.1c0.543 2.085 0.926 4.13 1.479 5.917 0.637 2.058 1.514 4.429 2.071 6.509 0.679 2.534 1.479 4.253 1.479 5.03 0 0.3 0.592 0.887 0.888 0.591 0.48 -0.479 -2.775 -9.139 -3.846 -13.017a40.178 40.178 0 0 1 -1.775 -13.313c0.3 -4.438 3.254 -7.988 7.4 -9.763a15.915 15.915 0 0 1 6.509 -1.184 19.156 19.156 0 0 1 5.917 0.592 7.591 7.591 0 0 1 0.591 1.479 2.306 2.306 0 0 0 1.184 1.183 7.015 7.015 0 0 0 5.917 0.592c7.342 -1.966 9.126 -4.136 13.905 -4.733a12.867 12.867 0 0 1 7.4 0.887 8.456 8.456 0 0 1 5.03 5.03c0.887 2.366 0.887 5.029 1.183 7.4 0.355 2.838 1.278 13.7 2.959 15.384 -0.008 -0.89 -0.304 -1.484 -0.304 -2.074Z" fill={stroke} strokeWidth="1"></path>
        <path d="M22.563 154.735c0.9 -0.372 2.784 6.659 4.438 10.65 3.441 8.309 3.9 10.435 3.55 10.651 -1.355 0.837 -4.626 -7.959 -5.326 -10.059 -0.359 -1.077 -3.097 -11.062 -2.662 -11.242Z" fill={stroke} strokeWidth="1"></path>
        <path d="M138.24 123.374c3.185 -8.492 6.213 -14.878 6.213 -17.159a5.261 5.261 0 0 0 -2.367 -3.254 40.731 40.731 0 0 1 -7.1 -8.876 6.2 6.2 0 0 1 -1.183 -3.254c-0.3 -3.254 3.55 -5.621 6.8 -5.325 3.255 0.591 5.917 2.958 8.284 5.325 -0.592 -1.479 1.183 -3.254 2.663 -2.959 3.911 0 12.485 9.929 10.65 25.739 -1.1 9.478 -5.917 12.426 -6.508 16.568 0 0 -7.1 -0.592 -6.805 -4.142s4.114 -5.023 3.254 -9.171c-0.918 -4.432 -4.733 -2.367 -3.846 -2.367 0 0 -2.721 11.8 -6.508 19.23 -3.907 7.662 -12.13 13.018 -17.16 9.467s-0.3 -9.763 -0.3 -9.763 -2.958 1.184 -2.662 -2.071 3.254 -6.8 5.621 -6.508 3.55 0.591 4.438 -0.888c-1.184 -1.775 -4.734 -2.663 -5.03 -5.029s0.3 -3.255 2.071 -3.551c1.775 -0.591 2.663 2.071 4.438 4.734 1.191 2.367 5.037 3.254 5.037 3.254Z" fill={primary} strokeWidth="1"></path>
        <path d="M60.432 110.357c1.183 -3.846 -0.275 -8.617 -3.846 -10.651a10.264 10.264 0 0 0 -11.539 0.592c0.3 0.3 3.255 2.663 4.438 3.55 -2.838 -0.315 -4.254 -3.254 -6.213 -3.254 -10.65 -1.775 -11.538 9.467 -12.13 12.426 -1.775 6.213 -5.621 9.467 -2.958 17.455 0.3 0 0.888 -0.3 1.183 -0.3a9.975 9.975 0 0 1 2.959 -9.763 17.179 17.179 0 0 0 -0.3 9.467 7.754 7.754 0 0 1 4.438 0.592c5.621 1.775 11.545 3.6 17.455 2.663 1.777 -0.282 3.55 -0.888 4.438 -2.071 -2.071 -1.776 -4.142 -4.734 -3.255 -9.763 -1.775 -0.888 -1.775 -2.959 -1.775 -4.142a12.824 12.824 0 0 0 7.105 -6.801Z" fill={stroke} strokeWidth="1"></path>
        <path d="M44.752 57.1c0.391 0 0.391 1.183 0 1.183s-0.392 -1.183 0 -1.183Z" fill={stroke} strokeWidth="1"></path>
        <path d="M49.017 59.964a12.337 12.337 0 0 0 0.468 -1.677 2.129 2.129 0 0 0 -1.185 -2.071 4.271 4.271 0 0 1 -2.29 -1.916c4.96 -3.687 1.553 -4.927 -0.106 -4.134 0.565 -0.871 0.115 -5.415 -3.815 -2.532 0 -1.184 -2.071 -2.959 -3.846 0 0.592 -1.184 -4.734 -2.663 -3.55 1.479 -1.184 -1.775 -5.326 0 -2.959 3.254 -0.592 -0.3 -4.733 2.071 -0.592 3.255 0.3 0 -4.141 2.071 0.3 3.55 -1.775 0.592 -0.887 4.142 2.367 2.958 -0.887 0.592 -0.3 3.255 2.367 1.775 0 0.3 0.592 1.48 1.775 0.3a36.393 36.393 0 0 0 0.888 8.284c0.3 -2.958 0.591 -2.071 0.591 -10.059 -2.366 1.479 -5.325 -3.846 0 -2.958 0 3.55 2.959 -3.255 5.03 -4.142 0.315 -0.18 0.678 -0.409 1.056 -0.669a20.327 20.327 0 0 0 1.9 2.444 1.18 1.18 0 0 1 0.592 1.183 5 5 0 0 1 -0.592 1.48v0.295l0.3 0.3a5.575 5.575 0 0 1 0.592 2.367c0 1.345 -0.894 3.846 -2.367 3.846 -0.887 0.3 -2.663 0 -2.663 0.3s2.071 0.3 2.959 0.3c3.141 -0.013 4.487 -4.329 2.78 -7.212ZM33.509 55.92c-0.591 0 -1.479 -2.958 1.48 -2.366a1.937 1.937 0 0 0 -1.48 2.366Zm0.888 -4.733c-0.3 -0.592 1.479 -3.255 2.958 -0.888a4.625 4.625 0 0 0 -2.955 0.888Zm6.509 -2.071c0 -0.3 -0.3 -1.184 1.183 -1.479 0.592 0 0.592 0.3 1.183 0.591a3.322 3.322 0 0 0 -2.366 0.888Z" fill={stroke} strokeWidth="1"></path>
        <path d="M95.934 55.92c1.143 12.134 6.187 16.289 8.579 19.822 3.221 4.757 1.759 13.351 9.764 16.864 8.857 3.887 7.467 -6.137 10.946 -9.467 2.72 -2.6 6.861 -4.722 7.988 -10.059 6.598 -31.259 -40.032 -46.394 -37.277 -17.16Zm14.5 16.864c-0.887 -0.3 -1.183 -1.183 -1.775 -1.775a2.538 2.538 0 0 1 -2.071 0c-2.068 -0.69 -2.366 -1.976 -2.366 -4.142a3.131 3.131 0 0 1 -1.48 -2.663 3.645 3.645 0 0 1 0.888 -2.367 4.2 4.2 0 0 1 -0.3 -5.029c0.592 -0.888 1.183 -1.183 1.479 -2.071 0 -0.3 -0.3 -0.592 -0.3 -1.183a3 3 0 0 1 2.663 -2.663c0 -4.929 5.325 -3.357 5.325 0.888 0.003 18.882 1.389 21.005 -2.067 21.005Zm14.5 -2.367c0.827 3.308 -3.368 5.508 -4.438 4.438 0 0.9 -1.76 3.565 -4.733 0.592 -1.164 -1.164 -0.888 -4.411 -0.888 -11.834 0 -11.1 -0.382 -12.087 1.479 -13.018a2.638 2.638 0 0 1 3.246 1.775 5.488 5.488 0 0 1 0.592 2.071c3.161 0 4.623 5.824 2.662 6.805a6.02 6.02 0 0 1 2.071 1.183c0.64 0.64 1.291 1.855 0.888 2.663a2.241 2.241 0 0 1 -0.592 1.183c1.615 1.613 0.995 2.853 -0.294 4.142Z" fill={primary} strokeWidth="1"></path>
        <path d="M124.927 66.275c-0.592 0.888 -1.479 0.888 -1.775 1.775a1.537 1.537 0 0 1 0.592 -1.183c3.654 -3.654 -1.454 -5 -2.663 -6.213h1.479a1.815 1.815 0 0 0 0.3 -1.775 5.483 5.483 0 0 0 -0.592 -2.071c-0.591 -1.479 -3.254 -2.367 -4.141 -2.367 0.3 -0.3 0.591 -0.3 1.183 -0.3 0 -0.3 0 -0.591 -0.592 -1.183 -1.275 -1.275 -0.719 -1.183 -2.367 -1.183 -1.269 0 -0.887 4.071 -0.887 16.271 0 4.754 -0.418 6.448 2.071 7.692 1.119 0.56 2.272 -1.786 2.663 -2.958v1.183a2.3 2.3 0 0 0 2.366 -0.591c1.584 -1.584 0.714 -2.367 0.888 -2.367a2.526 2.526 0 0 1 -2.071 0c2.005 0.004 4.254 -2.605 3.546 -4.73Zm-6.509 -8.875c0 2.518 -0.175 1.955 0.888 3.55a1.875 1.875 0 0 1 -1.775 -1.479 2.3 2.3 0 0 1 0.887 -2.071Zm1.182 11.242a3.2 3.2 0 0 0 -0.3 1.183c-0.933 -0.933 -0.1 -1.742 -0.1 -2.149a1.574 1.574 0 0 1 -1.381 -1.7c0 -0.3 0.3 -0.591 0.591 -0.887a2.263 2.263 0 0 0 0.888 2.071 0.29 0.29 0 0 0 0.3 0.3 2.139 2.139 0 0 1 2.662 -0.3c-0.975 0.003 -2.372 0.455 -2.66 1.482Z" fill={secondary} strokeWidth="1"></path>
        <path d="M111.91 54.145c0 -2.508 0.141 -2.381 -0.592 -3.846a2.315 2.315 0 0 0 -1.183 -1.183c-0.592 -0.3 -0.888 0.3 -1.184 0.592a6.44 6.44 0 0 0 -0.887 1.479 2.217 2.217 0 0 1 1.479 0.3 3.75 3.75 0 0 0 -1.775 0.3c-1.094 0.546 -2.736 2.219 -2.071 3.55 -0.3 0 -0.343 -0.176 -0.343 -0.472 -0.19 1.19 -2.877 3.465 0.047 6.389a16.6 16.6 0 0 0 2.367 1.479 9.4 9.4 0 0 1 -2.663 -0.888 2.5 2.5 0 0 0 -0.592 1.776 2.558 2.558 0 0 0 0.888 1.775 1.614 1.614 0 0 1 1.479 -1.196c-1.1 0.549 -1.071 2.887 -0.591 3.846a3.73 3.73 0 0 0 2.213 1.681s-0.333 -0.81 -0.142 -1.089c0.363 0.727 1.59 2.728 2.337 3.037 0.386 0.16 0.917 -0.374 0.917 -0.966v-14.5a4.383 4.383 0 0 0 0.296 -2.064Zm-3.846 5.03a1.78 1.78 0 0 1 -1.184 -0.3 4.8 4.8 0 0 0 1.775 -0.888 4.233 4.233 0 0 0 0.888 -2.071 1.81 1.81 0 0 1 -0.086 2.445 1.633 1.633 0 0 1 -1.393 0.814Zm1.479 5.029a3.505 3.505 0 0 0 -0.3 1.775 1.423 1.423 0 0 1 -0.592 -1.775 1.967 1.967 0 0 1 1.48 -1.183 3.537 3.537 0 0 0 -0.588 1.179Z" fill={secondary} strokeWidth="1"></path>
        <path d="M100.963 89.647c-4.513 -12.8 -36.924 -7.874 -28.7 9.172 0.575 1.191 1.765 1.484 0.887 -0.3C67.769 87.606 85.991 80.285 95.342 86.1c11.988 7.451 -0.3 17.751 -11.834 17.455a40.376 40.376 0 0 1 -5.621 3.255 0.29 0.29 0 0 1 -0.3 -0.3c1.775 -4.438 0.888 -5.325 -1.479 0.888 -0.3 0.591 0 1.183 0.591 0.887a64.939 64.939 0 0 0 7.1 -3.846c6.218 0.593 20.901 -4.204 17.164 -14.792Z" fill={stroke} strokeWidth="1"></path>
        <path d="M184.689 68.642c-17.463 -7.081 -33.431 11.538 -16.863 19.23a21.969 21.969 0 0 1 -2.367 4.438c0.327 0.1 7.277 -2.014 6.8 -2.958 -0.3 -0.592 -3.55 1.183 -4.733 1.775a7.893 7.893 0 0 0 1.479 -3.846c-14.2 -7.692 -1.78 -19.147 7.988 -18.639 20.4 1.062 15.68 16.864 4.438 18.343a20.955 20.955 0 0 1 -4.142 0.3c-2.367 0.295 -2.367 0.887 0.591 0.887 14.797 -0.004 20.708 -13.894 6.809 -19.53Z" fill={stroke} strokeWidth="1"></path>
        <path d="M29.072 40.536C26.6 30.723 1.245 31.764 5.4 43.791c0.827 2.389 2.621 3.864 1.48 1.183C0.124 29.1 41.5 35.211 22.267 49.412a6.665 6.665 0 0 0 0.3 2.071 0.636 0.636 0 0 1 -0.592 0.591 36.495 36.495 0 0 0 -3.55 -1.775c-2.071 -0.3 -3.254 0.592 -7.1 -0.887 -0.591 -0.3 -1.775 -0.592 -0.295 0.591 0.887 0.592 2.958 1.184 7.1 1.48a37 37 0 0 0 5.029 2.958c0.6 0.283 0.888 0 0.888 -0.591a28.532 28.532 0 0 0 -0.597 -3.55c3.255 -1.776 6.585 -5.936 5.622 -9.764Z" fill={stroke} strokeWidth="1"></path>
        <path d="M168.122 78.997a1.479 1.479 0 1 0 2.958 0 1.479 1.479 0 1 0 -2.958 0Z" fill={stroke} strokeWidth="1"></path>
        <path d="M174.926 78.997a1.479 1.479 0 1 0 2.958 0 1.479 1.479 0 1 0 -2.958 0Z" fill={stroke} strokeWidth="1"></path>
        <path d="M181.435 78.997a1.479 1.479 0 1 0 2.958 0 1.479 1.479 0 1 0 -2.958 0Z" fill={stroke} strokeWidth="1"></path>
        <path d="M77.887 94.085a1.479 1.479 0 1 0 2.958 0 1.479 1.479 0 1 0 -2.958 0Z" fill={stroke} strokeWidth="1"></path>
        <path d="M83.804 94.085a1.479 1.479 0 1 0 2.958 0 1.479 1.479 0 1 0 -2.958 0Z" fill={stroke} strokeWidth="1"></path>
        <path d="M90.017 94.085a1.479 1.479 0 1 0 2.958 0 1.479 1.479 0 1 0 -2.958 0Z" fill={stroke} strokeWidth="1"></path>
        <path d="M10.433 42.903a1.183 1.183 0 1 0 2.366 0 1.183 1.183 0 1 0 -2.366 0Z" fill={stroke} strokeWidth="1"></path>
        <path d="M15.167 42.903a1.183 1.183 0 1 0 2.366 0 1.183 1.183 0 1 0 -2.366 0Z" fill={stroke} strokeWidth="1"></path>
        <path d="M19.901 42.903a1.183 1.183 0 1 0 2.366 0 1.183 1.183 0 1 0 -2.366 0Z" fill={stroke} strokeWidth="1"></path>
        <path d="M41.2 90.831c3.255 -1.479 16.568 -6.509 19.822 -7.692 0 -2.367 0.888 -15.089 0.888 -15.089l-15.383 5.03 0.591 -1.479c-2.662 -1.776 -5.917 -2.663 -8.875 -3.846a30.417 30.417 0 0 0 -5.03 -0.3l1.187 5.033 -14.8 31.952 10.651 2.367c3.254 -10.947 14.2 -8.284 14.2 -8.284a11.048 11.048 0 0 1 7.692 -3.254l-0.3 -7.1s-7.091 2.07 -10.643 2.662Z" fill={primary} strokeWidth="1"></path>
      </svg>



    );
}
