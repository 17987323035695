import { useMemo, useState } from "react";
import GraphContainer from "./Graphs/GraphContainer";
import { CachedDocument, extractSubcollection } from "../adapters/Firebase/Firebase";
import combineGoNogoDataData from "./GonogoData";
import GoNogoReactionGraph from "./Graphs/GoNogoReactionGraph";
import { GonogoData } from "./GonogoData";
import AverageGonogoCard from "./Graphs/AverageGonogoCard";
import AIAssistant, { serializeAIData } from "./AIAssistant";


interface GoNogoGraphData {
	sessionDoc: CachedDocument
	children: any
	graphElements?: number
}

const aiPrompt = (data: any) =>
	"Here is the data of a session of a neurological patient using a VR go-nogo exercise in which many objects are presented sequentially in front of them and they must press a clicker in their hands only when the object is not the one indicated as a target:" +
	`\n${serializeAIData(data)}` +
	"\n- Pay attention to the color of the objects as well the similitude of the objects in shape (not in name)." +
	"\nPlease elaborate a short report in Spanish language that reasons about the data so a therapist can quickly understand it. Do not explain the data itself but the conclusions you can make out of it."

function reduceSessionData(data: GonogoData) {


	const tries = data.tries.map((t) => {
		return {
			hand: t.handedness,
			time: t.reactionTime?.toFixed(3),
			item: t.itemData,
			error: t.isError,
		}
	});

	return {
		//add target item
		tries: tries,
	}
}

function GonogoSession(props: GoNogoGraphData) {
	const session: CachedDocument = props.sessionDoc;

	const [sessionData, setSessionData] = useState<GonogoData | undefined>(undefined);

	useMemo(() => {
		extractSubcollection(session, "blocks").then((sessionBlocks: any) => {
			const data = combineGoNogoDataData(sessionBlocks[0] ?? sessionBlocks);
			setSessionData(data);
		})
	}, [session])

	if (sessionData === undefined) {
		return <></>;
	}

	return (
		<>
			<GraphContainer size='big' order={0} width={props.graphElements && props.graphElements > 1 ? `45%` : '100%'}>
				{props.children}
				<GoNogoReactionGraph
					tries={sessionData.tries} />
			</GraphContainer>
			<GraphContainer order={3} width={props.graphElements ? `${95 / props.graphElements}%` : '30%'}>
				{props.children}
				<AverageGonogoCard data={sessionData} />
			</GraphContainer>
		</>);
}

export default GonogoSession;
