import diswasherScreenshot from "../../media/landing/diswasher_screenshot.jpg";
import throwScreenshot from "../../media/landing/throw_screenshot.jpg";
import birdsScreenshot from "../../media/landing/birds_screenshot.jpg";
import grillScreenshot from "../../media/landing/grill_screenshot.jpg";
import cleanScreenshot from "../../media/landing/clean_screenshot.jpg";
import fruitsScreenshot from "../../media/landing/fruits_screenshot.jpg";

export const ourTechnologyData = {
	screenshots: [
		diswasherScreenshot,
		throwScreenshot,
		grillScreenshot,
		cleanScreenshot,
	],

	title: "[ourtechnology_title]",
	paragraph1: "[ourtechnology_paragraph1]",
	video: "https://firebasestorage.googleapis.com/v0/b/re-cognition.appspot.com/o/recognition_promo.mp4?alt=media"
}